// show form alert only on safari 7.1+
_::-webkit-full-page-media, _:future, :root .safari_only {
  display: block!important;
}

// typography
h5, .h5 {
  font-weight: 400;
}
h6, .h6 {
  font-weight: 500;
}
.qsi-site-header {
  .nav-item,
  .dropdown-item {
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    @include media-breakpoint-up(lg) {
      font-size: calc(13px + (300vw - 960px)/1600);
    }
  }
}

// helper classes
.bg-brand-red {background-color: $brand-red; }
.bg-brand-dark-gray {background-color: $brand-dark-gray; }
.bg-brand-green {background-color: $brand-green; }
.bg-brand-lt-gray {background-color: $brand-lt-gray; }
.text-lt-gray {color:$brand-lt-gray;}

a:focus-visible {
  outline: 2px solid $secondary;
  outline-offset: 2px;
}
.btn {
  position: relative;
}
.btn-primary {
  background-size: 200% 100%;
  background-position: 95%;
  background-image: linear-gradient(to right, #6b1912, #bf2013);
  transition: background-position 0.5s ease;
  &:hover {
    border-color: transparent;
    background-position: -1px;
  }
  @include btnWhiteArrow;
}

.btn-primary-back {
  background-size: 200% 100%;
  background-position: 95%;
  background-image: linear-gradient(to left, #6b1912, #bf2013);
  transition: background-position 0.5s ease;
  &:hover {
    border-color: transparent;
    background-position: -1px;
  }
  @include btnWhiteArrow;
}

.btn-secondary {
  background-size: 200% 100%;
  background-position: 95%;
  background-image: linear-gradient(to right, #3b424b, #4b575f, #5f777a);
  transition: background-position 0.5s ease;
  &:hover {
    border-color: transparent;
    background-position: -1px;
  }
  @include btnWhiteArrow;

}
.btn-outline-secondary {
  &:hover {
    background-color: transparent;
    border-color: $brand-red;
    color: $brand-red;
  }
  @include btnRedArrow;

}
//.btn-white-arrow {
//  @include btnWhiteArrow;
//}
//.btn-red-arrow {
//  @include btnRedArrow;
//}

.btn-link {
  color: $brand-green;
  &:hover {
    color: #3d4443;
  }
}

label.required::after,
legend.required::after {
  content: "*";
  color: #d00;
  margin-left: 5px;
}

.divider-left {
   &:after {
     content: '';
     display: block;
     position: relative;
     background-color: $link-color;
     height: 5px;
     width: 30px;
     margin-top: 1.25rem;
     margin-bottom: 1.25rem;
   }
}
.divider-center {
  &:after {
    content: '';
    display: block;
    position: relative;
    background-color: $link-color;
    height: 5px;
    width: 30px;
    margin: 1.25rem auto;
  }
}

.breadcrumb-item {
  &.active {
    a {
      color: #6f7974;
      pointer-events: none;
      cursor: auto;
    }
  }
}

.skip-link {
  position: absolute;
  font-weight: 500;
  background-color: $brand-tan;
  color: $secondary;
  display: block;
  padding: 1rem;
  text-align: center;
  left: -10000px;
  top: 5px;
  text-transform: uppercase;
  z-index: 10001;
  &:hover {
    color: $secondary;
    text-decoration: none;
  }
  &:focus {
    outline-color: $brand-tan;
    color: $secondary;
    left: 5px;
  }
}
.qsi-header-logo, {
  width: 105px;
  height: 95px;
  z-index: 2;
  position: relative;
}
.qsi-mobile-header-logo {
  width: 80px;
  height: 75px;
  z-index: 2;
  position: relative;
}
.qsi-sales-login-logo {
  width: 135px;
  height: 125px;
  z-index: 2;
  position: relative;
}
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
// Object-fit Background Images
.object-fit-bg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  z-index: 1;
}
.object-fit-img {
  display: block;
  object-fit: cover;
  font-family: "object-fit: cover";
  width: 100%;
  height: 100%;
}
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter .2s ease-in, -webkit-filter .2s ease-in;
  &.lazyloaded {
    filter: blur(0);
  }
}
//header
.qsi-search-dropdown-menu {
  min-width: 24rem;
  max-width: 300px;
}
#header-search-btn {
  box-shadow: none;
  outline: 2px solid transparent;
  &:focus {
    outline: 3px solid $white;
  }
}
.header-search-icon {
  width: 20px;
  height: 26px;
  fill: $white;
}
#mobile-search-form {
  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
// footer
footer a {
  color: $brand-green;
  &:hover {
    color: #3d4443;
  }
}
.qsi-nav-columns {
  height: 150px;
  .nav-link {
    font-size: 0.9375rem;
  }
}
.qsi-footer-info {
  border-top: 2px solid $brand-red;
}
.qsi-footer-social-link {
  background-color: $brand-lt-gray;
  svg {
    fill: $brand-dark-gray;
    width: 25px;
    height: 25px;
  }
  &:hover {
    background-color: $brand-dark-gray;
    svg {
      fill: $white;
    }
  }
}
.qsi-contact-info,
.qsi-affiliations-list {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 50%;
  }
}
.qsi-affiliations-list {
  > li {
    width: 50%;
    img {
      max-height: 65%;
      max-width: 60%;
    }
    a {
      &:hover {
        outline: 2px solid $secondary;
        outline-offset: 2px;
      }
      &:focus {
        outline: 2px solid $secondary;
        outline-offset: 2px;
      }
    }
    @include media-breakpoint-up(xmd) {
      width: 25%;
    }
  }
}
//.qsi-affiliation-img {
//  img {
//    max-height: 65%;
//    max-width: 60%;
//  }
//  width: 50%;
//  @include media-breakpoint-up(xmd){
//    width: 25%;
//  }
//}
// embeds
.video-embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  @include embedIframe();
}