@mixin btnArrowAfter {
  position: absolute;
  content: '';
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  top: 50%;
  right: 15px;
  transform: translate(0px, -50%);
  width: 6px;
  height: 12px;
  transition: transform .2s ease;
}
@mixin btnWhiteArrow {
  padding-right: 2rem;
  &:after {
    @include btnArrowAfter;
    background-image: escape-svg($white-arrow);
  }
  &:hover {
    &:after {
      transform: translate(6px, -50%);
    }
  }
}
@mixin btnRedArrow {
  padding-right: 2rem;
  &:after {
    @include btnArrowAfter;
    background-image: escape-svg($red-arrow);
  }
  &:hover {
    &:after {
      transform: translate(6px, -50%);
    }
  }
}
@mixin embedIframe() {
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin e($element) {
  &__#{$element} {
    @content;
  }
}

@mixin m($element) {
  &--#{$element} {
    @content;
  }
}