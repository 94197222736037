#gallery-sidebar,
#gallery-listing {
  @media screen and (max-width: 991px) {
    &.col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
#gallery-sidebar {
  > .disclosure__content {
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
  @media screen and (min-width: 992px) {
    max-width: 250px;
  }
}

.disclosure {
  margin-bottom: 1rem;
  + .disclosure:last-of-type {
    margin-bottom: 0;
  }
  @include e(toggle) {
    -webkit-appearance: button;
    background-color: darken($light, 5);
    background-image: none;
    //border-left: 1px solid transparent;
    //border-right: 1px solid transparent;
    //border-bottom: 1px solid $brand-lt-gray;
    //border-top: 1px solid transparent;
    border: 1px solid transparent;
    cursor: pointer;
    padding: .5rem .75rem;
    position: relative;
    text-transform: none;
    width: 100%;
    &::after {
      border: solid $primary;
      border-width: 0 2px 2px 0;
      content: '';
      display: inline-block;
      height: 12px;
      margin-right: 0.125rem;
      padding: 3px;
      position: absolute;
      right: 0.75rem;
      top: 50%;
      -webkit-transform: translateY(-75%) rotate(45deg);
      transform: translateY(-75%) rotate(45deg);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      width: 12px;
    }
    &[aria-expanded="true"] {
      &::after {
        -webkit-transform: translateY(-25%) rotate(-135deg);
        transform: translateY(-25%) rotate(-135deg);
      }
    }
    &:hover {
      //background-color: $;
    }
    @include m(parent) {
      background-color: $primary;
      color: $white;
      @media screen and (min-width: 992px) {
        display: none;
      }
      + .disclosure__content {
        border: 1px solid $brand-lt-gray;
        @media screen and (min-width: 992px) {
          border: 1px solid transparent;
          padding: 0;
        }
      }
      &::after {
        border-color: $white;
      }
    }
  }
  @include e(content) {
    display: none;
    padding: 0.5rem;
    //border-bottom: 1px solid $brand-lt-gray;
    //border-top: 1px solid darken($light, 5);
    &.show {
      display: block;
    }
  }
}

.gallery-link {
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.lum-lightbox-inner img {
  max-height: 90%;
  max-width: 90%;
}
.lum-lightbox-inner .lum-lightbox-caption {
  background-color: #fff;
  color: $body-color;
  max-width: 1200px;
  width: 90%;
  padding: 1.25rem;
  text-align: left;
  @media (max-width: 460px) {
    position: relative;
  }
}

.lum-close-button {
  background-color: $primary;
  height: 42px;
  opacity: 1;
  width: 42px;
  &::before, &::after {
    left: 20px;
    top: 4px;
  }
}
.lum-lightbox {
  z-index: 1001;
}